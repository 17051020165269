import { getLiturgy, postLiturgy } from '@/api/more'

const state = {
  liturgy: ''
}
const mutations = {
  setLiturgy (state, value) {
    state.liturgy = value
  }
}
const actions = {
  getLiturgyAction ({ commit }, { success, error }) {
    getLiturgy(response => {
      commit('setLiturgy', response.data.data)
      success && success(response)
    }, error)
  },
  changeLiturgyAction ({ commit, state }, { success, error, data }) {
    postLiturgy(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  liturgy (state) {
    return state.liturgy
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
