import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// if (process.env.NODE_ENV === 'development') require('@/api-mock')
Vue.config.productionTip = false
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
