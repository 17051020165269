import { getArticles, deleteArticle, createArticle, updateArticle } from '@/api/article'

const state = {
  articles: []
}
const mutations = {
  setArticles (state, value) {
    state.articles = [...value]
  }
}
const actions = {
  getArticlesAction ({ commit }, { success, error, data }) {
    getArticles(response => {
      commit('setArticles', response.data.data)
      success && success(response)
    }, error, data)
  },
  deleteArticleAction ({ commit, state }, { success, error, data }) {
    deleteArticle(response => {
      commit('setArticles',
        state.articles.filter(articles => articles.id !== data.id))
      success && success(response)
    }, error, data)
  },
  createArticleAction ({ commit, state }, { success, error, data }) {
    createArticle(response => {
      success && success(response)
    }, error, data)
  },
  editArticleAction ({ commit, state }, { success, error, data }) {
    updateArticle(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  articles (state) {
    return state.articles
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
