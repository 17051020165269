import { api } from '@/config'
import { getDataViaApi, postDataViaApi } from '@/utils/HttpApi'
import { serializeQueryParams } from '@/utils/query'

const getImages = (callBack, errorHandler, data) => {
  const params = serializeQueryParams(data)
  getDataViaApi(api.images.base + '/_all' + params, callBack, errorHandler)
}
const createImages = (callBack, errorHandler, data) => {
  postDataViaApi(api.images.base, callBack, errorHandler, data)
}
const deleteImages = (callBack, errorHandler, data) => {
  postDataViaApi(api.images.specific(data), callBack, errorHandler)
}

export {
  getImages,
  createImages,
  deleteImages
}
