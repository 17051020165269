import { getCategories,
  getContents,
  addCategories,
  addContents,
  deleteCategories,
  deleteContents,
  updateContents } from '@/api/pdf'

export default {
  namespaced: true,
  state: {
    categories: []
  },
  mutations: {
    setCategories (state, payload) {
      state.categories = [...payload]
    }
  },
  actions: {
    fetchCategories ({ commit }, { success, fail } = {}) {
      getCategories(response => {
        commit('setCategories', response.data.data)
        success && success(response)
      }, fail)
    },
    fetchContents ({ commit }, { success, fail, categoryId } = {}) {
      getContents(response => {
        success && success(response.data.data)
      }, fail, categoryId)
    },
    addNewCategories ({ commit }, { success, fail, data } = {}) {
      addCategories(response => {
        success && success(response.data.data)
      }, fail, data)
    },
    addNewContents ({ commit }, { success, fail, data } = {}) {
      addContents(response => {
        success && success(response.data.data)
      }, fail, data)
    },
    deleteOldCategories ({ commit }, { success, fail, categoryId, data } = {}) {
      deleteCategories(response => {
        success && success(response.data.data)
      }, fail, categoryId, data)
    },
    deleteOldContents ({ commit }, { success, fail, data } = {}) {
      deleteContents(response => {
        success && success(response.data.data)
      }, fail, data)
    },
    updateOldContents ({ commit }, { success, fail, data } = {}) {
      updateContents(response => {
        success && success(response.data.data)
      }, fail, data)
    }
  }
}
