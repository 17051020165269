import { getAdmins,
  getAdmin,
  addAdmin,
  updateAdmin,
  deleteAdmin,
  rolesAdmin } from '@/api/admin'

const state = {
  admins: [],
  admin: {},
  availableRoles: []
}
const mutations = {
  setAdmins (state, value) {
    state.admins = [...value]
  },
  setAdmin (state, value) {
    state.admin = { ...value }
  },
  setAvailableRoles (state, value) {
    state.availableRoles = [...value]
  }
}
const actions = {
  getAdminsAction ({ commit }, { success, error }) {
    getAdmins(response => {
      const data = response.data.data
      commit('setAdmins', data)
      success && success(response)
    }, error)
  },
  getAdminAction ({ commit }, { success, error, id }) {
    getAdmin(response => {
      const data = response.data.data
      commit('setAdmin', data)
      success && success(response)
    }, error, id)
  },
  addAdminAction ({ commit }, { success, error, data }) {
    addAdmin(response => {
      success && success(response)
    }, error, data)
  },
  updateAdminAction ({ commit }, { success, error, data }) {
    updateAdmin(response => {
      success && success(response)
    }, error, data)
  },
  deleteAdminAction ({ commit }, { success, error, data }) {
    deleteAdmin(response => {
      success && success(response)
    }, error, data)
  },
  rolesAdminAction ({ commit }, { success, error } = {}) {
    rolesAdmin(response => {
      const data = response.data.data
      commit('setAvailableRoles', data)
      success && success(response)
    }, error)
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
