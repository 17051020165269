import Cookies from 'js-cookie'

const setCookie = ({ key, value, expires = null }) => {
  if (expires) Cookies.set(key, value, { expires })
  Cookies.set(key, value)
}

const getCookie = key => {
  return Cookies.get(key)
}

const removeCookie = key => {
  Cookies.remove(key)
}

export {
  setCookie,
  getCookie,
  removeCookie
}
