const basePath = '/backend/gki'
const centerPath = '/backend/gki/center'
const app = {
  cookies: {
    token: 'gunsa_center_token'
  },
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Des'],
  version: '0.15.0',
  features: {
    ARTICLE: 'Article',
    BANNER: 'Banner',
    SCHEDULE: 'Schedule',
    FELLOWSHIP: 'Persekutuan',
    WORSHIP: 'Ibadah Umum',
    IMAGES: 'Images',
    PDF: 'pdf',
    ADMIN: 'Admin'
  }
}

const api = {
  user: {
    login: (data) => `${basePath}/user?username=${data.username}&password=${data.password}`,
    get: token => `${centerPath}/user/${token}`
  },
  banner: {
    base: `${basePath}/banner`,
    delete: ({ id, token }) => `${basePath}/banner/${id}/${token}`
  },
  detailArticle: id => `${basePath}/article/${id}`,
  event: {
    base: `${basePath}/events`,
    specific: id => `${basePath}/events/${id}`,
    delete: ({ id, token }) => `${basePath}/events/${id}/${token}`
  },
  liturgy: link => `${basePath}/files/location?link=${link}`,
  article: {
    base: `${basePath}/article`,
    get: `${centerPath}/article`,
    specific: id => `${basePath}/article/${id}`,
    delete: ({ id, token }) => `${basePath}/article/${id}/${token}`,
    expiredDate: `${basePath}/article/expired-date`
  },
  donation: {
    base: `${basePath}/donation`,
    get: `${centerPath}/donation`,
    specific: id => `${basePath}/donation/${id}`,
    delete: ({ id, token }) => `${basePath}/donation/${id}/${token}`,
    expiredDate: `${basePath}/donation/expired-date`
  },
  worship: {
    base: `${basePath}/worship`,
    specific: sequence => `${basePath}/worship/${sequence}`
  },
  images: {
    base: `${basePath}/images`,
    specific: ({ id, token }) => `${basePath}/images/${id}/${token}`
  },
  admin: {
    base: `${centerPath}/admin`,
    add: `${centerPath}/admin/add`,
    specific: (id) => `${centerPath}/admin/${id}`,
    roles: '/backend/gki/center/admin/roles/_all'
  },
  pdf: {
    categories: `${basePath}/pdf/categories`,
    specificCategories: categoryId => `${basePath}/pdf/categories/${categoryId}`,
    contents: `${basePath}/pdf/contents`,
    specificContents: categoryId => `${basePath}/pdf/contents/${categoryId}`,
    specificUpdateContents: contentId => `${basePath}/pdf/contents/${contentId}`,
    specificCategoriesContents: ({ categoryId, contentId }) => `${basePath}/pdf/contents/${categoryId}/${contentId}`
  }
}

const page = {

}

export {
  app,
  api,
  page
}
