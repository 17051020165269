import { getDonation,
  createDonation,
  deleteDonation,
  updateDonation } from '@/api/donation'

const state = {
  donations: []
}
const mutations = {
  setDonations (state, value) {
    state.donations = [...value]
  }
}
const actions = {
  getDonationAction ({ commit }, { success, error }) {
    getDonation(response => {
      commit('setDonations', response.data.data)
      success && success(response)
    }, error)
  },
  deleteDonationAction ({ commit, state }, { success, error, data }) {
    deleteDonation(response => {
      commit('setDonations',
        state.donations.filter(donation => donation.id !== data.id))
      success && success(response)
    }, error, data)
  },
  createDonationAction ({ commit, state }, { success, error, data }) {
    createDonation(response => {
      success && success(response)
    }, error, data)
  },
  editDonationAction ({ commit, state }, { success, error, data }) {
    updateDonation(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  donations (state) {
    return state.donations
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
