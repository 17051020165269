import { getEvents, createEvent, deleteEvent, editEvent } from '@/api/event'

const state = {
  events: []
}
const mutations = {
  setEvents (state, value) {
    state.events = [...value]
  }
}
const actions = {
  getEventsAction ({ commit }, { success, error }) {
    getEvents(response => {
      commit('setEvents', response.data.data)
      success && success(response)
    }, error)
  },
  deleteEventAction ({ commit, state }, { success, error, data }) {
    deleteEvent(response => {
      commit('setEvents',
        state.events.filter(event => event.id !== data.id))
      success && success(response)
    }, error, data)
  },
  createEventAction ({ commit, state }, { success, error, data }) {
    createEvent(response => {
      success && success(response)
    }, error, data)
  },
  editEventAction ({ commit, state }, { success, error, data }) {
    editEvent(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  events (state) {
    return state.events
  },
  publics (state) {
    return state.events.filter(event => event.type === 'PUBLIC')
  },
  teens (state) {
    return state.events.filter(event => event.type === 'TEEN')
  },
  kids (state) {
    return state.events.filter(event => event.type === 'KIDS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
