import { api } from '@/config'
import { getDataViaApi, postDataViaApi, putDataViaApi } from '@/utils/HttpApi'

const getDonation = (callBack, errorHandler) => {
  getDataViaApi(api.donation.get + '/_all?hide=false', callBack, errorHandler)
}

const getDetailDonation = (callBack, errorHandler, id) => {
  getDataViaApi(api.donation.specific(id), callBack, errorHandler)
}

const createDonation = (callBack, errorHandler, data) => {
  postDataViaApi(api.donation.base, callBack, errorHandler, data.donation)
}

const deleteDonation = (callBack, errorHandler, data) => {
  postDataViaApi(api.donation.delete(data), callBack, errorHandler, data)
}

const updateDonation = (callBack, errorHandler, data) => {
  putDataViaApi(api.donation.specific(data.id), callBack, errorHandler, data.donation)
}

export {
  getDonation,
  getDetailDonation,
  createDonation,
  deleteDonation,
  updateDonation
}
