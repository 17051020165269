import { api } from '@/config'
import { getDataViaApi, putDataViaApi } from '@/utils/HttpApi'

const getWorships = (callBack, errorHandler) => {
  getDataViaApi(api.worship.base + '/_all', callBack, errorHandler)
}
const editWorship = (callBack, errorHandler, data) => {
  putDataViaApi(api.worship.specific(data.sequence), callBack, errorHandler, data.worship)
}

export {
  getWorships,
  editWorship
}
