import { api } from '@/config'
import { getDataViaApi, postDataViaApi } from '@/utils/HttpApi'

const getBanners = (callBack, errorHandler) => {
  getDataViaApi(api.banner.base + '/_all', callBack, errorHandler)
}
const createBanners = (callBack, errorHandler, data) => {
  postDataViaApi(api.banner.base, callBack, errorHandler, data)
}
const deleteBanners = (callBack, errorHandler, data) => {
  postDataViaApi(api.banner.delete(data), callBack, errorHandler, data)
}

export {
  getBanners,
  createBanners,
  deleteBanners
}
