import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import article from './modules/article'
import banner from './modules/banner'
import event from './modules/event'
import more from './modules/more'
import donation from './modules/donation'
import worship from './modules/worship'
import image from './modules/image'
import admin from './modules/admin'
import pdf from './modules/pdf'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    article,
    banner,
    event,
    more,
    donation,
    worship,
    image,
    admin,
    pdf
  }
})
