import { getWorships, editWorship } from '@/api/worship'

const state = {
  worships: []
}
const mutations = {
  setWorships (state, value) {
    state.worships = [...value]
  }
}
const actions = {
  getWorshipsAction ({ commit }, { success, error }) {
    getWorships(response => {
      commit('setWorships', response.data.data)
      success && success(response)
    }, error)
  },
  editWorshipAction ({ commit, state }, { success, error, data }) {
    editWorship(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  worships (state) {
    return state.worships
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
