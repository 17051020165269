import { getBanners, createBanners, deleteBanners } from '@/api/banner'

const state = {
  banners: []
}
const mutations = {
  setBanners (state, value) {
    state.banners = [...value]
  }
}
const actions = {
  getBannersAction ({ commit }, { success, error }) {
    getBanners(response => {
      commit('setBanners', response.data.data)
      success && success(response)
    }, error)
  },
  deleteBannersAction ({ commit, state }, { success, error, data }) {
    deleteBanners(response => {
      commit('setBanners',
        state.banners.filter(articles => articles.id !== data.id))
      success && success(response)
    }, error, data)
  },
  createBannersAction ({ commit, state }, { success, error, data }) {
    createBanners(response => {
      success && success(response)
    }, error, data)
  }
}
const getters = {
  banners (state) {
    return state.banners
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
