import { login, user } from '@/api/user'
import { getCookie, removeCookie } from '@/utils/Cookie'
import { app } from '@/config'
const state = {
  token: null,
  isLoggedIn: false,
  currentUser: {}
}
const mutations = {
  setToken (state, value) {
    state.token = value
  },
  setIsLoggedIn (state, value) {
    state.isLoggedIn = value
  },
  setCurrentUser (state, value) {
    state.currentUser = { ...value }
  }
}
const actions = {
  loginAction ({ commit }, { success, data, error }) {
    login(response => {
      const token = response.data.data
      commit('setToken', token)
      success && success(response)
    }, error, data)
  },
  logOut ({ commit }) {
    removeCookie(app.cookies.token)
    commit('setToken', null)
    commit('setIsLoggedIn', false)
    commit('setCurrentUser', {})
  },
  getUser ({ commit }, { success, fail, token }) {
    user(response => {
      const token = getCookie(app.cookies.token)
      if (token) commit('setToken', token)
      commit('setIsLoggedIn', true)
      commit('setCurrentUser', response.data.data)
      success && success(response)
    }, fail, token)
  }
}
const getters = {
  token (state) {
    return state.token
  },
  roles (state) {
    return state.currentUser.roles
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
