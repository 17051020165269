import { api } from '@/config'
import { getDataViaApi, postDataViaApi, deleteDataViaApi, putDataViaApi } from '@/utils/HttpApi'

const getCategories = (callBack, errorHandler) => {
  getDataViaApi(api.pdf.categories, callBack, errorHandler)
}

const getContents = (callBack, errorHandler, categoryId) => {
  getDataViaApi(api.pdf.specificContents(categoryId), callBack, errorHandler)
}

const addCategories = (callBack, errorHandler, data) => {
  postDataViaApi(api.pdf.categories, callBack, errorHandler, data)
}

const addContents = (callBack, errorHandler, data) => {
  postDataViaApi(api.pdf.contents, callBack, errorHandler, data)
}

const deleteCategories = (callBack, errorHandler, categoryId, data) => {
  deleteDataViaApi(api.pdf.specificCategories(categoryId), callBack, errorHandler, data)
}

const deleteContents = (callBack, errorHandler, data) => {
  deleteDataViaApi(api.pdf.specificCategoriesContents(data), callBack, errorHandler, { token: data.token })
}

const updateContents = (callBack, errorHandler, data) => {
  putDataViaApi(api.pdf.specificUpdateContents(data.id), callBack, errorHandler, data.content)
}

export {
  getCategories,
  getContents,
  addCategories,
  addContents,
  deleteCategories,
  deleteContents,
  updateContents
}
