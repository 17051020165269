import { api } from '@/config'
import { getDataViaApi, postDataViaApi, deleteDataViaApi, putDataViaApi } from '@/utils/HttpApi'

const getAdmins = (callBack, errorHandler) => {
  getDataViaApi(api.admin.base + '/_all', callBack, errorHandler)
}

const getAdmin = (callBack, errorHandler, id) => {
  getDataViaApi(api.admin.specific(id), callBack, errorHandler)
}

const addAdmin = (callBack, errorHandler, data) => {
  const request = {
    ...data.admin,
    token: data.token
  }
  postDataViaApi(api.admin.add, callBack, errorHandler, request)
}

const updateAdmin = (callBack, errorHandler, data) => {
  const request = {
    ...data.admin,
    token: data.token
  }
  if (!data.editPassword) delete request.password
  putDataViaApi(api.admin.specific(data.id), callBack, errorHandler, request)
}

const deleteAdmin = (callBack, errorHandler, data) => {
  deleteDataViaApi(api.admin.specific(data.id), callBack, errorHandler, { token: data.token })
}

const rolesAdmin = (callBack, errorHandler) => {
  getDataViaApi(api.admin.roles, callBack, errorHandler)
}

export {
  getAdmins,
  getAdmin,
  addAdmin,
  updateAdmin,
  deleteAdmin,
  rolesAdmin
}
