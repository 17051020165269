import { api } from '@/config'
import { getDataViaApi, postDataViaApi, putDataViaApi } from '@/utils/HttpApi'

const getEvents = (callBack, errorHandler) => {
  getDataViaApi(api.event.base + '/_all', callBack, errorHandler)
}
const createEvent = (callBack, errorHandler, data) => {
  postDataViaApi(api.event.base, callBack, errorHandler, data.event)
}
const deleteEvent = (callBack, errorHandler, data) => {
  postDataViaApi(api.event.delete(data), callBack, errorHandler, data)
}
const editEvent = (callBack, errorHandler, data) => {
  putDataViaApi(api.event.specific(data.id), callBack, errorHandler, data.event)
}

export {
  getEvents,
  createEvent,
  deleteEvent,
  editEvent
}
