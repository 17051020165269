import { api } from '@/config'
import { getDataViaApi, postDataViaApi, putDataViaApi } from '@/utils/HttpApi'
import { serializeQueryParams } from '@/utils/query'

const getArticles = (callBack, errorHandler, data) => {
  const params = serializeQueryParams(data)
  getDataViaApi(api.article.get + '/_all' + params, callBack, errorHandler)
}

const getDetailArticle = (callBack, errorHandler, id) => {
  getDataViaApi(api.article.specific(id), callBack, errorHandler)
}

const createArticle = (callBack, errorHandler, data) => {
  postDataViaApi(api.article.base, callBack, errorHandler, data.article)
}

const deleteArticle = (callBack, errorHandler, data) => {
  postDataViaApi(api.article.delete(data), callBack, errorHandler, data)
}

const updateArticle = (callBack, errorHandler, data) => {
  putDataViaApi(api.article.specific(data.id), callBack, errorHandler, data.article)
}

export {
  getArticles,
  getDetailArticle,
  createArticle,
  deleteArticle,
  updateArticle
}
