import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getCookie } from '@/utils/Cookie'
const Homepage = () => import(/* webpackChunkName = "p-homepage" */ '@/pages/Homepage')
const Login = () => import(/* webpackChunkName = "p-login" */ '@/pages/Login')
const Article = () => import(/* webpackChunkName = "p-article" */ '@/pages/Article')
const Banner = () => import(/* webpackChunkName = "p-banner" */ '@/pages/Banner')
const Schedule = () => import(/* webpackChunkName = "p-banner" */ '@/pages/Schedule')
const Pdf = () => import(/* webpackChunkName = "p-pdf" */ '@/pages/Pdf')
const Fellowship = () => import(/* webpackChunkName = "p-fellowship" */ '@/pages/Fellowship')
const Worship = () => import(/* webpackChunkName = "p-worship" */ '@/pages/Worship')
const Images = () => import(/* webpackChunkName = "p-images" */ '@/pages/Images')
const Admin = () => import(/* webpackChunkName = "p-admin" */ '@/pages/Admin')

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/article',
    name: 'Article',
    component: Article
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/pdf',
    name: 'Pdf',
    component: Pdf
  },
  {
    path: '/fellowship',
    name: 'Fellowship',
    component: Fellowship
  },
  {
    path: '/worship',
    name: 'Worship',
    component: Worship
  },
  {
    path: '/images',
    name: 'Images',
    component: Images
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true
    }
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = getCookie('gunsa_center_token')
  store.dispatch('user/getUser', {
    success: () => {
      if (to.path === '/login') {
        next('/dashboard')
        return
      }
      next()
    },
    fail: () => {
      store.dispatch('user/logOut')
      if (!to.meta.public) {
        next('/login')
        return
      }
      next()
    },
    token: token
  })
  next()
})

export default router
