import { api } from '@/config'
import { getDataViaApi, postDataViaApi } from '@/utils/HttpApi'

const login = (callBack, errorHandler, data) => {
  postDataViaApi(api.user.login(data), callBack, errorHandler)
}
const user = (callBack, errorHandler, token) => {
  getDataViaApi(api.user.get(token), callBack, errorHandler)
}

export {
  login,
  user
}
