import { getImages, createImages, deleteImages } from '@/api/images'

const state = {
  images: [],
  types: [],
  selectedType: null,
  selectedImagesId: []
}
const mutations = {
  setImages (state, value) {
    state.images = [...value]
  },
  setTypes (state, value) {
    state.types = [...value]
  },
  setSelectedType (state, value) {
    state.selectedType = value
  },
  setSelectedImagesId (state, value) {
    state.selectedImagesId = [...value]
  }
}
const actions = {
  getImagesAction ({ commit }, { success, error, data }) {
    getImages(response => {
      commit('setImages', response.data.data.images)
      commit('setTypes', response.data.data.types)
      success && success(response)
    }, error, data)
  },
  deleteImagesAction ({ commit, state }, { success, error, data }) {
    deleteImages(response => {
      success && success(response)
    }, error, data)
  },
  createImagesAction ({ commit, state }, { success, error, data }) {
    createImages(response => {
      success && success(response)
    }, error, data)
  },
  setSelectedTypeAction ({ commit }, type) {
    commit('setSelectedType', type)
  },
  setSelectedImagesIdAction ({ commit, state }, id) {
    if (state.selectedImagesId.includes(id)) {
      commit('setSelectedImagesId', state.selectedImagesId.filter(item => item !== id))
    } else {
      commit('setSelectedImagesId', [...state.selectedImagesId, id])
    }
  },
  deleteMultipleImagesAction ({ commit, state }, { success, error, token }) {
    state.selectedImagesId.forEach(id => {
      deleteImages(response => {
        if (id === state.selectedImagesId[state.selectedImagesId.length - 1]) {
          commit('setSelectedImagesId', [])
          success && success(response)
        }
      }, error, { id, token })
    })
  }
}
const getters = {
  images (state) {
    return state.images
  },
  types (state) {
    return state.types
  },
  selectedType (state) {
    return state.selectedType
  },
  selectedImagesId (state) {
    return state.selectedImagesId
  },
  selectedImagesTitle (state) {
    return state.images.filter(item => state.selectedImagesId.includes(item.id)).map(item => item.name)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
