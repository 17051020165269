import { api } from '@/config'
import { getDataViaApi, postDataViaApi } from '@/utils/HttpApi'

const getLiturgy = (callBack, errorHandler) => {
  getDataViaApi(api.liturgy, callBack, errorHandler)
}

const postLiturgy = (callBack, errorHandler, data) => {
  postDataViaApi(api.liturgy(data), callBack, errorHandler, data)
}

export {
  getLiturgy,
  postLiturgy
}
